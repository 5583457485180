"use client";
import React, { useContext } from "react";
import { useRouter } from "next/navigation";
import { Context } from "@context/store";

const ClickableIcon = ({ amenity }) => {
  const { icon, name } = amenity;
  const [{ bookingInfo }, dispatch] = useContext(Context);
  const router = useRouter();

  const handleFilter = async () => {
    dispatch({
      type: "bookingInfo",
      payload: { ...bookingInfo, amenities: [amenity._id] },
    });
    router?.push("/AllProperties");
  };

  return (
    <div className="hotel-category" onClick={handleFilter}>
      <div className="category-icon">
        <div className="preview" dangerouslySetInnerHTML={{ __html: icon }} />
      </div>
      <span className="category-text">{name}</span>
    </div>
  );
};

export default ClickableIcon;
