"use client";
import React, { useContext } from "react";
import { Context } from "@context/store";
import Card from "@/components/HotelCard";
function RecentlyViewed() {
  const [state] = useContext(Context);
  console.log(state);
  if (!state.recentlyViewedProperties) {
    return null;
  }
  return (
    <div className="container mt-5 py-4">
      <h2 className="mt-5">Last Viewed Hotels</h2>
      <div className="outside-container">
        <div className="card-container">
          {state.recentlyViewedProperties
            ?.slice(0, 4)
            .map(
              (item) =>
                item && (
                  <Card
                    key={item.property._id}
                    property={item.property}
                    previewImages={item.images}
                  />
                )
            )}
        </div>
      </div>
    </div>
  );
}

export default RecentlyViewed;
