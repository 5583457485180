"use client";
import ClickableIcon from "@/components/ClickableIcon";
import { Store } from "@/context/store";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect } from "react";

function AmenitiesSlider() {
  const [amenities, setAmenities] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    fetch(
      process.env.NEXT_PUBLIC_SERVER_BASE_URL +
        "/api/guest/amenities/getAllPropertyAmenities",
      {
        next: { revalidate: 3600 },
      }
    )
      .then((res) => res.json())
      .then((res) => setAmenities(res.data));
  }, []);

  return (
    <div className="container">
      <div className="heading-container">
        <h2 className="mt-5 headline-main">
          Discover hotels that suit your needs
        </h2>
        <div className="scroll-container">
          <button
            className="scroll-button"
            onClick={() => containerRef.current?.scrollBy({ left: -200 })}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            className="scroll-button"
            onClick={() => containerRef.current?.scrollBy({ left: 200 })}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
      <div className="card-container-sm" ref={containerRef}>
        {amenities.map((amenity, index) => (
          <ClickableIcon key={index} amenity={amenity} />
        ))}
      </div>
    </div>
  );
}

export default AmenitiesSlider;
